import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ServicioPreLiquidacion {

  url = environment.API_URL + '/api/preliquidacion/';

  constructor(private http: HttpClient) { }

  public getData(id) {
    return this.http.get<any>(this.url + id);
  }

  public readAll(modelo) {
    return this.http.post<any>(this.url + 'GetAll', modelo);
  }

  public readAllFromEmpleado(modelo) {
    return this.http.post<any>(this.url + 'GetAllFromEmpleado', modelo);
  }

  public delete(id) {
    return this.http.delete<any>(this.url + id);
  }

  public create(modelo) {
    return this.http.post<any>(this.url, modelo);
  }

  public getDataViewer(id) {
    return this.http.get<any>(this.url + 'GetDataViewer/' + id);
  }
}
