import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ServicioAnticipo {

  url = environment.API_URL + '/api/anticipo/';

  constructor(private http: HttpClient) { }

  public readAll(modelo) {
    return this.http.post<any>(this.url + 'GetAll', modelo);
  }

  public update(modelo) {
    return this.http.put<any>(this.url, { anticipo: modelo });
  }

  public updateAll(modelo) {
    return this.http.post<any>(this.url + 'UpdateAll', modelo);
  }

  public delete(id) {
    return this.http.delete<any>(this.url + id);
  }

  public create(modelo) {
    return this.http.post<any>(this.url, { anticipo: modelo, tipoanticipo: null, empleado: null });
  }

  public getData() {
    return this.http.get<any>(this.url);
  }
}
