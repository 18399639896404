import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ServicioPago {
      
    url = environment.API_URL + '/api/pago/';

    constructor(private http: HttpClient) { }

    public getData() {
        return this.http.get<any>(this.url);
    }

    public readAll(modelo) {
        return this.http.post<any>(this.url + 'GetAll', modelo);
    }

    public readAllFacturas(modelo) {
      return this.http.post<any>(this.url + 'GetFacturas', modelo);
    }

  public update(modelo) {
    modelo.fecha = moment(modelo.fecha).format('YYYY-MM-DD');
    return this.http.put<any>(this.url, { pago: modelo });
  }

  public delete(id) {
    return this.http.delete<any>(this.url + id);
  }

  public create(modelo) {
    modelo.fecha = moment(modelo.fecha).format('YYYY-MM-DD');
    return this.http.post<any>(this.url, { pago: modelo });
  }
}
