import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class ServicioLiquidacion {

    url = environment.API_URL + '/api/liquidacion/';

    constructor(private http: HttpClient) { }

    public getData(id) {
        return this.http.get<any>(this.url + id);
    }

    public readAll(modelo) {
        return this.http.post<any>(this.url + 'GetAll', modelo);
    }

    public update(modelo) {
        return this.http.put<any>(this.url, { liquidacion: modelo });
    }

    public delete(id) {
        return this.http.delete<any>(this.url + id);
    }

    public create(modelo) {
        return this.http.post<any>(this.url, { liquidacion: modelo });
    }

    public getDataViewer(id) {
        return this.http.get<any>(this.url + 'GetDataViewer/' + id);
      }
}
